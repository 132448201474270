import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import Spanish from '@/assets/languages/Spanish'
// import English from '@/assets/languages/English'
// import Portuguese from '@/assets/languages/Portuguese'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: window.localStorage.language || 'es',
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('es').create(Spanish)
    // new MLanguage('en').create(English),
    // new MLanguage('pt').create(Portuguese)
  ]
})
