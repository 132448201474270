import Vue from 'vue'

export default {
  getUser (callback, errorHandler) {
    return Vue.http.get('user/view').then(callback, errorHandler)
  },
  postUser (payload, callback, errorHandler) {
    return Vue.http.post('open/user', payload).then(callback, errorHandler)
  },
  subscribeNewsletter (payload, callback, errorHandler) {
    return Vue.http.post('open/newsletter', payload).then(callback, errorHandler)
  },
  sendContactForm (payload, callback, errorHandler) {
    return Vue.http.post('open/contact', payload).then(callback, errorHandler)
  },
  recoverPassword (payload, callback, errorHandler) {
    return Vue.http.post('open/forgot/password', payload).then(callback, errorHandler)
  },
  resetPassword (payload, callback, errorHandler) {
    return Vue.http.post('open/reset/password', payload).then(callback, errorHandler)
  },
}
