const state = {
  user: {
    email: '',
    password: ''
  },
  auth: {
    access_token: localStorage.access_token,
    refresh_token: localStorage.refresh_token
  },
  language: localStorage.language || navigator.language || navigator.userLanguage
}

// getters are functions
const getters = {
  user: state => state.user,
  auth: state => state.auth,
  language: state => state.language
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  updateUser ({ commit }, user) {
    commit('setUser', user)
  },
  authenticate ({ commit }, tokens) {
    localStorage.setItem('access_token', tokens.access_token)
    localStorage.setItem('refresh_token', tokens.refresh_token)

    commit('setTokens', tokens)
  },
  updateLanguage ({ commit }, language) {
    localStorage.setItem('language', language)
    commit('setLanguage', language)
  },
  logout ({ commit }) {
    commit('setTokens', {
      access_token: null,
      refresh_token: null
    })

    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
  }
}

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes. (Syncronous)
const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setTokens (state, tokens) {
    state.auth = tokens
  },
  setLanguage (state, language) {
    state.language = language
  }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
  state,
  getters,
  actions,
  mutations
}
