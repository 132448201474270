import Vue from 'vue'

export default {
  getOpenOrder (uuid, callback, errorHandler) {
    return Vue.http.get(`open/order/${uuid}`).then(callback, errorHandler)
  },
  postOrder (payload, callback, errorHandler) {
    return Vue.http.post(`user/order`, payload).then(callback, errorHandler)
  },
}
