import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/iniciar',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      hideHeader: false
    }
  },
  {
    path: '/registro',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta: {
      hideHeader: false
    }
  },
  {
    path: '/recuperar',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue'),
    meta: {
      hideHeader: false
    }
  },
  {
    path: '/restablecer/:token',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue'),
    meta: {
      hideHeader: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue'),
    meta: {
      hideHeader: true
    }
  },
  {
    path: '/acerca',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/catalogos',
    name: 'catalogs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Catalogs.vue')
  },
  {
    path: '/programas',
    name: 'programs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Programs.vue')
  },
  {
    path: '/programa/:uuid',
    name: 'program',
    component: () => import(/* webpackChunkName: "about" */ '../views/Program.vue')
  },
  {
    path: '/sesiones',
    name: 'sessions',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sessions.vue')
  },
  {
    path: '/cursos',
    name: 'courses',
    component: () => import(/* webpackChunkName: "about" */ '../views/Courses.vue')
  },
  {
    path: '/carerra',
    name: 'careers',
    component: () => import(/* webpackChunkName: "about" */ '../views/Careers.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "about" */ '../views/Checkout.vue')
  },
  {
    path: '/perfil',
    name: 'profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/perfil/programa/:uuid',
    name: 'profileProgram',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileProgram.vue')
  },
  {
    path: '/seleccionar-plan',
    name: 'selectPlan',
    component: () => import(/* webpackChunkName: "about" */ '../views/SelectPlan.vue')
  },
  {
    path: '/spei/:uuid',
    name: 'spei',
    component: () => import(/* webpackChunkName: "about" */ '../views/SPEI.vue')
  },
  {
    path: '/oxxo/:uuid',
    name: 'oxxo',
    component: () => import(/* webpackChunkName: "about" */ '../views/OXXO.vue')
  },
  {
    path: '/gracias',
    name: 'thankYou',
    component: () => import(/* webpackChunkName: "about" */ '../views/ThankYou.vue')
  },
  {
    path: '/paypal/:status',
    name: 'paypal',
    component: () => import(/* webpackChunkName: "about" */ '../views/PayPal.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue')
  },
  {
    path: '/politica-de-privacidad',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/notfound',
    component: () => import(/* webpackPrefetch: true */ '../views/NotFound')
  },
  {
    path: '*',
    component: () => import(/* webpackPrefetch: true */ '../views/NotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  // scrollBehavior () {return { x: 0, y: 0 }},
  scrollBehavior (to, from, savedPosition) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      let timeout = (savedPosition ? 200 : 400)
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        } else {
          window.scrollTo(0, 0)
          resolve({ x: 0, y: 0 })
        }
      }, timeout)
    })
  },
  routes: routes
})

// Router Login Guard
router.beforeEach((to, from, next) => {
  if (!store.getters.auth.access_token && to.path !== '/login' && to.path !== '/register') {
    // next('/login')
    next()
  } else {
    next()
  }
})

export default router
