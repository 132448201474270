<template>
  <div id="header">
    <v-app-bar
      app clipped-left
      color="white"
      class="elevation-0"
      height="70"
    >
      <!--Logo-->
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 pointer"
          cover
          :src="require('../assets/images/logos/logo.png')"
          transition="scale-transition"
          width="80"
          @click="$router.push({name: 'home'})"
        />
      </div>
      <v-spacer></v-spacer>

      <!--Menu desktop-->
      <div
        v-for="(item, index) in items"
        :key="index"
        class="hidden-sm-and-down"
      >
        <v-btn
          v-if="item.route"
          tile text
          class="metropolis-regular font-14 lowercase"
          :class="{ 'active-btn': $route.name === item.route }"
          @click="$router.push({name: item.route})"
          :color="item.color"
        >
          <span :class="item.class">{{ item.title }}</span>
        </v-btn>
        <a v-else :href="item.href" target="_blank" style="text-decoration: unset">
          <v-btn
            tile text
            class="metropolis-regular font-14 lowercase"
            :color="item.color"
          >
            <span :class="item.class">{{ item.title }}</span>
          </v-btn>
        </a>
      </div>
      <div class="ml-2 hidden-sm-and-down metropolis-regular font-14 lowercase pink--text">
        <!--Hola, {{ g_user.first_name }}-->
        <v-icon
          color="pink"
          class="mr-1"
          @click="$router.push({name: 'profile'})"
          v-if="g_auth.access_token"
        >
          mdi-account-circle
        </v-icon>
        <span
          v-if="g_auth.access_token"
          @click="$router.push({name: 'profile'})"
          class="pink--text mr-4 pointer"
        >Hola, {{ g_user.first_name }}</span>
        <!--<span class="metropolis-regular font-14 mr-3">-->
        <!--  {{ g_user.email }}-->
        <!--</span>-->
        <v-badge
          color="pink"
          :content="cart.hasOwnProperty('programs') ? String(cart.programs.length) : '0'"
          class="mr-2"
        >
          <v-btn width="20" height="20" icon small :to="{name: 'checkout'}">
            <v-img
              :src="require('../assets/images/icons/carrito.png')"
              width="22"
              height="22"
              contain
            ></v-img>
          </v-btn>
        </v-badge>
        <!--<v-btn icon small @click="$router.push({name: 'logout'})">-->
        <!--  <v-icon>mdi-exit-to-app</v-icon>-->
        <!--</v-btn>-->
      </div>

      <v-app-bar-nav-icon @click="drawer = true" class="hidden-md-and-up mr-0">
        <v-icon large color="pink">mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <!--Mobile menu-->
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        app
      >
        <v-list
          dense
          nav
        >
          <v-list-item
            link
            class="metropolis-semi-bold"
            @click="$router.push({name: 'home'})"
          >
            <v-list-item-content>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-for="item in items">
            <v-list-item
              v-if="item.route"
              :key="item.title"
              link
              class="metropolis-semi-bold"
              @click="$router.push({name: item.route})"
            >
              <v-list-item-content>
                <v-list-item-title :class="item.class">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <a
              v-else
              :key="item.title"
              :href="item.href"
              target="_blank"
              style="text-decoration: unset"
            >
              <v-list-item
                :key="item.title"
                link
                class="metropolis-semi-bold"
              >
                <v-list-item-content>
                  <v-list-item-title :class="item.class">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </a>
          </template>

          <!--User-->
          <v-list-item
            v-if="g_auth.access_token"
            link
            class="metropolis-semi-bold"
            :to="{name: 'profile'}"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon
                  color="pink"
                  class="mr-1"
                  @click="$router.push({name: 'profile'})"
                >
                  mdi-account-circle
                </v-icon>
                <span class="pink--text">Hola, {{ g_user.first_name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--Cart-->
          <v-list-item
            link
            class="metropolis-regular"
            :to="{name: 'checkout'}"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-badge
                  color="pink"
                  :content="cart.hasOwnProperty('programs') ? String(cart.programs.length) : '0'"
                  class="mr-2"
                >
                  <v-btn width="20" height="20" icon small>
                    <v-img
                      :src="require('../assets/images/icons/carrito.png')"
                      width="22"
                      height="22"
                      contain
                    ></v-img>
                  </v-btn>
                </v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-navigation-drawer>

    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data: () => ({
      drawer: false,
      mobile_menu_dialog: false,
      mini: true,
    }),
    computed: {
      ...mapGetters([
        'cart'
      ]),
      items () {
        let items = [
          { title: 'Acerca de', icon: '', route: 'about' },
          { title: 'Carrera en Diseño Floral', icon: '', route: 'careers' },
          { title: 'Cursos', icon: '', route: 'courses' },
          { title: 'Sesiones en Vivo', icon: '', route: 'sessions' },
          { title: 'Blog', icon: '', href: 'https://blog.imtf.com.mx/' },
        ]
        if (!this.g_auth.access_token) {
          items.push({ title: 'Iniciar sesión', icon: '', route: 'login', class: 'pink--text' },)
        } else {
          // items.push({ title: 'Cerrar sesión', icon: '', route: 'logout', class: 'pink--text' },)
        }
        return items
      }
    },
    methods: {
      openMenu () {
        this.$vuetify.breakpoint.xsOnly ? this.mobile_menu_dialog = !this.mobile_menu_dialog : this.drawer = !this.drawer
      },
      getCart () {
        if (localStorage.getItem('cart')) {
          this.$api.getCart(localStorage.getItem('cart'),
            response => {
              this.$store.dispatch('updateCart', response.body.data)
            }, response => {
              if (response.status === 400 && response.body.error === 6) {
                this.localStorage.removeItem('cart')
                this.$store.dispatch('updateCart', {})
              } else
                this.$errorHandling(response)
            }
          )
        }
      },
    }
  }
</script>

<style scoped>
  .active-btn {
    border-bottom: 2px solid #D5D081;
  }
</style>
