import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        pink: '#DC4368',
        darkgray: '#616161',
        lightgray: '#F3F3F3',
        lightgreen: '#D5D081',
      },
    },
  },
});
