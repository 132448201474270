<template>
  <v-footer
    class="px-0 py-5"
    color="#fff"
    :app="$route.meta.footerApp"
  >
    <!-- <div
      @click="$vuetify.goTo(0)"
      class="scroll-top-button"
      :class="{ 'hidden': offsetTop < 20 }"
    >
      <v-icon color="white">mdi-chevron-up</v-icon>
    </div> -->
    <v-snackbar bottom left v-model="snackbar">
      Te has suscrito al newsletter
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          class="lowercase"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="py-0">
      <v-row class="justify-center align-center metropolis-regular darkgray-text">
        <v-col cols="12" sm="10" md="4">
          <v-img
            :src="images.logo"
            :lazy-src="images.logo"
            width="90" height="82"
            class="mb-5"
            :class="{ 'ma-auto': $vuetify.breakpoint.xsOnly }"
          ></v-img>
          <v-container class="py-0">
            <p class="metropolis-semi-bold mb-0">Boletín informativo</p>
            <v-form v-model="valid">
              <v-row class="text-center align-center">
                <v-col cols="7" sm="8" class="pr-1 py-0 px-sm-0">
                  <v-text-field
                    v-model="email"
                    label="Escribe tu correo"
                    class="pr-sm-3"
                    color="pink"
                    :rules="g_email_rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="4" class="pl-1 py-0 px-sm-0">
                  <v-btn
                    rounded depressed
                    color="pink"
                    class="white--text metropolis-semi-bold lowercase"
                    :disabled="!valid"
                    :loading="g_isLoading"
                    @click="subscribeNewsletter"
                  >
                    Suscríbete
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
        <v-col
          cols="5" sm="4" md="3"
          class="d-flex justify-center text-left"
        >
          <div>
            <p class="metropolis-semi-bold">Menú</p>
            <p class="pointer" @click="$router.push({name: 'about'})">Acerca de</p>
            <p class="pointer" @click="$router.push({name: 'programs'})">
              Programas
            </p>
            <p class="pointer" @click="$router.push({name: 'catalogs'})">
              Catálogos
            </p>
            <a href="https://blog.imtf.com.mx/" target="_blank" style="text-decoration: unset; color: unset">
              <p class="pointer" @click="$router.push({name: 'catalogs'})">
                Blog
              </p>
            </a>
          </div>
        </v-col>
        <v-col
          cols="7" sm="4" md="3"
          :class="{ 'd-flex justify-center': $vuetify.breakpoint.xsOnly }"
        >
          <div>
            <p class="metropolis-semi-bold">Servicio al cliente</p>
            <a href="tel:8009004683" style="text-decoration: unset; color: unset">
              <p>800 900 4683</p>
            </a>
            <p
              v-if="$route.name !== 'home'"
              @click="$router.push({name: 'home', hash: '#contact'})"
              class="pointer"
            >
              Planteles
            </p>
            <p v-else>
              <a
                href="#contact"
                style="text-decoration: unset; color: unset"
              >
                Planteles
              </a>
            </p>
            <p @click="$router.push({name: 'faq'})" class="pointer">
              Preguntas frecuentes
            </p>
             <p @click="$router.push({name: 'privacyPolicy'})" class="pointer">
              Política de privacidad
            </p>
          </div>
        </v-col>
        <v-col
          cols="12" sm="4" md="2"
          :class="{ 'd-flex justify-center text-center': $vuetify.breakpoint.xsOnly }"
        >
          <div>
            <p class="metropolis-semi-bold">Síguenos en redes </p>
            <a href="https://www.facebook.com/imtfmx/" target="_blank" style="text-decoration: unset">
              <v-icon class="mr-4">mdi-facebook</v-icon>
            </a>
            <a href="https://www.pinterest.com.mx/imtf/" target="_blank" style="text-decoration: unset">
              <v-icon class="mr-4">mdi-pinterest</v-icon>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <message-dialog :dialog="g_dialog"></message-dialog>
  </v-footer>
</template>

<script>

export default {
  data () {
    return {
      images: {
        logo: require('../assets/images/logos/logo.png'),
      },
      offsetTop: 0,
      valid: true,
      snackbar: false,
      email: null
    }
  },
  created () {
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    openLink (url) {
      window.open(url, '_blank')
    },
    subscribeNewsletter () {
      this.g_isLoading = true
      this.$api.subscribeNewsletter({email: this.email},
        () => {
          this.g_isLoading = false
          this.snackbar = true
          this.email = null
        }, () => {
          this.g_isLoading = false
          this.g_dialog = {
            title: this.$ml.get('general_error'),
            message: this.$ml.get('general_error_try_later'),
            show: true
          }
        }
      )
    },
    onScroll () {
      this.offsetTop = window.scrollY
    }
  }
}
</script>

<style scoped>
  .incap-logo {
    max-width: 300px;
    transition: .5s ease;
  }
  .incap-logo:hover {
    /*transform: translateY(-10px);*/
    transform: scale(1.1);
  }
  .scroll-top-button {
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: black;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s linear;
  }
  .hidden {
    opacity: 0;
    visibility: hidden;
  }
</style>
