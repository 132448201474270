import Vue from 'vue'

export default {
  auth (user, callback, errorHandler) {
    var auth = {
      grant_type: 'password',
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      username: user.email,
      password: user.password
    }
    return Vue.http.post('oauth/v2/token', auth).then(callback, errorHandler)
  },
  authRefresh (callback, errorHandler) {
    var auth = {
      grant_type: 'refresh_token',
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      refresh_token: localStorage.refresh_token
    }
    return Vue.http.post('oauth/v2/token', auth).then(callback, errorHandler)
  }
}
