import Vue from 'vue'

export default {
  getCart (uuid, callback, errorHandler) {
    return Vue.http.get(`open/cart/${uuid}`).then(callback, errorHandler)
  },
  newCartProduct (payload, callback, errorHandler) {
    return Vue.http.patch(`open/cart`, payload).then(callback, errorHandler)
  },
  addCartProduct (uuid, payload, callback, errorHandler) {
    return Vue.http.patch(`open/cart/${uuid}`, payload).then(callback, errorHandler)
  },
  removeCartProduct (uuid, payload, callback, errorHandler) {
    return Vue.http.patch(`open/cart/remove/program/${uuid}`, payload).then(callback, errorHandler)
  },
  validatePromoCode (code, cart, callback, errorHandler) {
    return Vue.http.get(`open/promo-code/validate/${code}/${cart}`).then(callback, errorHandler)
  },
}
