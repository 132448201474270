import Vue from 'vue'

export default {
  getCampuses (callback, errorHandler) {
    return Vue.http.get(`open/campuses`).then(callback, errorHandler)
  },
  getCampusesAutocomplete (callback, errorHandler) {
    return Vue.http.get(`open/campuses/autocomplete`).then(callback, errorHandler)
  }
}
