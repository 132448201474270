<script>
  import { mapGetters } from 'vuex'
  const MessageDialog = () => import('../components/MessageDialog')
  const LoadingDialog = () => import('../components/LoadingDialog')
  import { zonedTimeToUtc, format } from 'date-fns-tz'
  import { es } from 'date-fns/locale'
  export default {
    data () {
      return {
        g_isLoading: false,
        g_dialog: {
          title: '',
          message: '',
          show: false
        },
        g_required_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field')
        ],
        g_password_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field'),
          v => (!!v && v.length > 7) || this.$ml.get('rules_min_length_8')
        ],
        g_email_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field'),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$ml.get('rules_invalid_email')
        ],
        g_phone_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field'),
          v => (!!v && v.length > 9) || this.$ml.get('rules_invalid_phone')
        ],
        g_credit_card_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => (!!v && window.Conekta.card.validateNumber(v)) || this.$ml.get('rules_invalid_credit_card')
        ],
        g_expiration_month_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => (!!v && parseInt(v) < 13 && parseInt(v) > 0) || this.$ml.get('rules_invalid_date')
        ],
        g_expiration_year_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => (!!v && window.Conekta.card.validateExpirationDate(this.card_data.exp_month, v)) || this.$ml.get('rules_invalid_date')
        ],
        g_cvc_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => (!!v && window.Conekta.card.validateCVC(v)) || this.$ml.get('rules_invalid_cvc')
        ]
      }
    },
    computed: {
      ...mapGetters({
        g_auth: 'auth',
        g_stateUser: 'user',
        g_language: 'language'
      }),
      g_user () {
        const newUser = {...this.g_stateUser}
        return newUser
      }
    },
    components: {
      MessageDialog,
      LoadingDialog,
    },
    methods: {
      $auth (auth, redirect) {
        this.g_isLoading = true
        this.$api.auth(auth,
          response => {
            this.$store.dispatch('authenticate', {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token
            })
            this.$authGetUser(redirect)
          },
          response => this.$errorHandling(response)
        )
      },
      $refreshToken () {
        this.$api.authRefresh(
          response => {
            var tokens = {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token
            }
            this.$store.dispatch('authenticate', tokens)
          }, () => {}
        )
      },
      $errorHandling (response) {
        if (response.status === 401) {
          return new Promise((resolve, reject) => {
            this.$api.authRefresh(
              response => {
                this.$store.dispatch('authenticate', {
                  access_token: response.data.access_token,
                  refresh_token: response.data.refresh_token
                })
                this.$authGetUser(false, resolve)
              },
              () => {
                reject(Error('Invalid refresh token.'))
                this.$authLogout()
                this.g_dialog = {
                  title: this.$ml.get('general_error'),
                  message: this.$ml.get('error_sesion_expired'),
                  show: true
                }
                this.g_isLoading = false
              }
            )
          })
        } else if (response.status === 403) {
          this.g_dialog = {
            title: this.$ml.get('general_dialog_error'),
            message: this.$ml.get('general_forbidden'),
            show: true
          }
          this.$authLogout()
          return new Promise((resolve, reject) => {
            this.g_isLoading = false
            reject(Error('Forbidden access'))
          })
        } else if (response.status === 0) {
          return new Promise((resolve, reject) => {
            this.g_dialog = {
              title: this.$ml.get('general_dialog_error'),
              message: this.$ml.get('general_no_internet'),
              show: true
            }
            this.g_isLoading = false
            reject(Error('Not connected to internet.'))
          })
        } else if (response.status === 400 && response.body.error === 'invalid_grant') {
          return new Promise((resolve, reject) => {
            this.g_dialog = {
              title: this.$ml.get('general_dialog_error'),
              message: this.$ml.get('general_auth_failed'),
              show: true
            }
            this.g_isLoading = false
            reject(Error(response.body.error_description))
          })
        } else {
          return new Promise((resolve, reject) => {
            this.g_dialog = {
              title: this.$ml.get('general_dialog_error'),
              message: (response.body && response.body.description) || (response.body && response.body.error_description),
              show: true
            }
            this.g_isLoading = false
            reject(Error(response.body.description))
          })
        }
      },
      $authGetUser (redirect, resolve) {
        this.g_isLoading = true
        this.$api.getUser(
          response => {
            this.$store.dispatch('updateUser', response.body.data)
            this.g_isLoading = false
            if (redirect) {
              this.$router.push(redirect)
            }
            if (resolve) {
              return resolve(true)
            }
          }, response => this.$errorHandling(response).then(() => {}, () => {})
        )
      },
      $authLogout () {
        this.g_isLoading = true
        this.$store.dispatch('logout')
        this.$router.push('/')
        this.g_isLoading = false
      },
      $scrollTo (target) {
        this.$nextTick(() => {
          this.$vuetify.goTo(target, this.g_scrollOptions)
        })
      },
      $getFormattedDate (date) {
        if (!date) return null
        date = zonedTimeToUtc(date, 'America/Mexico_City')
        return format(date, 'EEEE dd MMM y', {locale: es})
      }
    }
  }
</script>
