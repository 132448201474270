import Vue from 'vue'

export default {
  postPaymentConekta (uuid, payload, callback, errorHandler) {
    return Vue.http.post(`user/payment/conekta/${uuid}`, payload).then(callback, errorHandler)
  },
  postPaymentConektaSpei (uuid, payload, callback, errorHandler) {
    return Vue.http.post(`user/payment/conekta/spei/${uuid}`, payload).then(callback, errorHandler)
  },
  postPaymentConektaOxxo (uuid, payload, callback, errorHandler) {
    return Vue.http.post(`user/payment/conekta/oxxo/${uuid}`, payload).then(callback, errorHandler)
  },
  postPaymentPayPalUrl (uuid, payload, callback, errorHandler) {
    return Vue.http.post(`open/paypal/url/${uuid}`, payload).then(callback, errorHandler)
  },
  postPaymentPayPal (uuid, payload, callback, errorHandler) {
    return Vue.http.post(`open/checkout/paypal/${uuid}`, payload).then(callback, errorHandler)
  },
  postFreePayment (uuid, payload, callback, errorHandler) {
    return Vue.http.post(`user/payment/free/${uuid}`, payload).then(callback, errorHandler)
  },
}
