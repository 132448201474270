// import Vue from 'vue'

import Auth from '@/api/Auth.js'
import User from '@/api/User.js'
import Program from '@/api/Program.js'
import Cart from '@/api/Cart.js'
import Payment from '@/api/Payment.js'
import Order from '@/api/Order.js'
import Catalog from '@/api/Catalog.js'
import File from '@/api/File.js'
import Teacher from '@/api/Teacher.js'
import Campus from '@/api/Campus.js'
import Faq from '@/api/Faq.js'

export default {
  ...Auth,
  ...User,
  ...Program,
  ...Cart,
  ...Payment,
  ...Order,
  ...Catalog,
  ...File,
  ...Teacher,
  ...Campus,
  ...Faq,
  install (Vue) {
    Vue.prototype.$api = Vue.api = this
  }
}
