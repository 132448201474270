const state = {
  cart: {},
}

// getters are functions
const getters = {
  cart: state => state.cart,
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  updateCart ({ commit }, cart) {
    commit('setCart', cart)
  }
}

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes. (Syncronous)
const mutations = {
  setCart (state, cart) {
    state.cart = cart
  }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default {
  state,
  getters,
  actions,
  mutations
}
