<template>
  <v-app>
    <!--<div v-if="g_isLoading" class="d-flex fill-height align-center justify-center flex-column">-->
    <!--  <img-->
    <!--    style="max-width: 200px" alt=""-->
    <!--    src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-light.png"-->
    <!--  />-->
    <!--  <v-progress-circular indeterminate color="info" class="mt-5" :size="50"></v-progress-circular>-->
    <!--</div>-->
    <template>
      <Header v-if="!$route.meta.hideHeader"></Header>
      <v-main>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-main>
      <Footer></Footer>
    </template>
  </v-app>
</template>

<script>
  import Header from "./components/Header";
  import Footer from "./components/Footer";
  export default {
    name: 'App',
    components: {Header, Footer},
    data: () => ({}),
    created () {
      if (this.g_auth.access_token) {
        // this.$authGetUser()
        this.refreshToken()
      }
    },
    methods: {
      refreshToken () {
        this.$api.authRefresh(
          response => {
            const tokens = {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token
            };
            this.$store.dispatch('authenticate', tokens)
            this.getUser()
          }, () => { this.$store.dispatch('logout') }
        )
      },
      getUser () {
        this.$api.getUser(
          response => {
            let user = {...response.body.data}
            this.$store.dispatch('updateUser', user)
          }, response => {
            this.$errorHandling(response).then(() => {
              this.getUser()
            }, () => {})
          }
        )
      }
    },
    metaInfo: {
      title: 'IMTF',
      titleTemplate: '%s | Instituto Mexicano Técnico Floral',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
  };
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
