import Vue from 'vue'

export default {
  getPrograms (query, callback, errorHandler) {
    return Vue.http.get(`open/programs?${query}`).then(callback, errorHandler)
  },
  getProgram (uuid, callback, errorHandler) {
    return Vue.http.get(`open/program/${uuid}`).then(callback, errorHandler)
  },
  getUserPrograms (type, callback, errorHandler) {
    return Vue.http.get(`user/programs/${type}`).then(callback, errorHandler)
  },
  getUserProgram (uuid, callback, errorHandler) {
    return Vue.http.get(`user/program/${uuid}`).then(callback, errorHandler)
  },
  putProgramProgress (uuid, progress, callback, errorHandler) {
    return Vue.http.put(`user/program-progress/${uuid}/${progress}`).then(callback, errorHandler)
  },
  putVideoProgress (programUuid, videoUuid, progress, callback, errorHandler) {
    return Vue.http.put(`user/video-progress/${programUuid}/${videoUuid}/${progress}`).then(callback, errorHandler)
  },
}
