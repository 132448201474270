export default {
  // General
  general_cancel: 'Cancelar',
  general_accept: 'Aceptar',
  general_go_home: 'Regresar a inicio',
  general_dialog_error: 'Error',
  general_dialog_success: 'Éxito',
  general_no_internet: 'No es posible conectar con el servidor. Por favor verifique su conexión a internet.',
  general_auth_failed: 'Nombre de usuario o contraseña incorrecta. Por favor verifique sus credenciales.',
  general_not_found: 'Objeto no encontrado.',
  general_user_exists: 'El usuario ya existe.',
  general_cancel_subscription: 'Cancelar suscripción',
  general_change_payment_method: 'Cambiar método de pago',
  general_login: 'Iniciar sesión',
  general_empty: '',
  general_error: 'Error',
  general_error_try_later: 'Favor de intentarlo mas tarde',
  genera_newsletter: 'Newsletter',
  genera_newsletter_subscribed: 'Te has suscrito a nuestro newsletter',
  genera_forgot_password_sent: 'Te hemos enviado las instrucciones para recuperar tu contraseña a tu correo.',
  genera_reset_password: 'Tu contraseña ha sido cambiada.',

  // Rules
  rules_required_field: 'Campo requerido.',
  rules_invalid_format: 'Formato inválido.',
  rules_invalid_email: 'Email inválido.',
  rules_min_length_8: 'La longitud mínima es 8',
  rules_invalid_phone: 'Numero de telefono invalido.',
  rules_invalid_phone_ext: 'Número de extensión de teléfono no válido.',
  rules_invalid_zip_code: 'Código postal inválido.',
  rules_invalid_credit_card: 'Número de tarjeta inválido.',
  rules_invalid_date: 'Fecha inválida.',
  rules_invalid_cvc: 'CVC inválido.',
  rules_url: 'URL inválido (tiene que incluir http o https)',

  // Login
  login_title: 'Iniciar sesión',
  register_with_facebook: 'Registrarse con facebook',
  register_with_google: 'Registrarse con google',

  // Header
  header_menu_logout: 'Cerrar sesión',

  // Footer
  footer_terms: 'Términos y condiciones',
  footer_newsletter_success: 'Has quedado suscrito a nuestro newsletter.',

  // Fields
  fields_name: 'Nombre',

  // Not found
  not_found_title: 'Error 404 - Página no encontrada',
  not_found_description: 'No pudimos encontrar la página que estabas buscando.',

  // Errors

  // Actions
}
