import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueResource from 'vue-resource'
import GlobalMixins from './mixins/GlobalMixins'
import Api from './api'
import store from './store'
import './ml'
import './assets/css/css.css'
import VueCurrencyFilter from 'vue-currency-filter'
import VueMeta from 'vue-meta'

Vue.use(Vuex)
Vue.use(VueResource)
Vue.use(Api)

Vue.use(VueCurrencyFilter,
  {
    symbol : '$',
    thousandsSeparator: ',',
    fractionCount: 0,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  })

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// HTTP Requests Interceptors
Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Content-Type', 'application/json')
  if (!request.headers.map.Authorization) {
    request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
  }
  next()
})

Vue.config.productionTip = false

Vue.mixin(GlobalMixins)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
